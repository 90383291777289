import React from 'react'
import {
    RoadmapContainer,
    RoadmapWrapper,
    RoadmapTitleWrapper,
    RoadmapTitle,
    ImgWrapper,
    Img,
} from './RoadmapElements'
import mainImg from '../../assets/Roadmap.png'

const RoadmapSection = () => {
    return(
        <RoadmapContainer id="roadmap" name="roadmap">
            <RoadmapWrapper id="roadmap-wrapper">
                <RoadmapTitleWrapper id="roadmap-title-wrapper">
                    <RoadmapTitle id="roadmap-title">Roadmap</RoadmapTitle>
                </RoadmapTitleWrapper>
                <ImgWrapper id="roadmap-img-wrapper">
                    <Img src={mainImg} />
                </ImgWrapper>
            </RoadmapWrapper>
        </RoadmapContainer>
    )
}

export default RoadmapSection