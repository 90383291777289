import React from 'react'
import {
  Container,
  Paper,
} from '@mui/material'

import {
    AboutContainer,
    AboutWrapper,
    ListWrapper,
    ContentWrapper,
    List,
    TitleWrapper,
    Title,
} from './AboutElements'
import ListItem from './ListItem'

const Line = () => {
  const style = {
    "display": "block",
    "marginTop": "0.5em",
    "marginBottom": "0.5em",
    "marginLeft": "auto",
    "marginRight": "auto",
    "borderStyle": "inset",
    "borderWidth": "1px",
    "color": "gray",
    "background": "none ",
  }
  return(
    <hr style={style} />
  )
}

const AboutSection = () => {
    return(
        <AboutContainer id="about">
          <AboutWrapper id="about-wrapper">
              <Container id="about-content-container">
                <Paper elevation={3} id="about-paper">
                  <ContentWrapper id="about-content-wrapper">
                    <ListWrapper id="about-list-wrapper">
                      <List id="about-list">
                        <ListItem label="Backgrounds" count="54" original={43} rare={11} rareChance={20} id="about-backgrounds" rareItemLibido={10} />
                        <Line />
                        <ListItem
                          label="Bodies"
                          count="102"
                          isAlt={true}
                          originalStyles={13}
                          originalVariations={50}
                          rare={39}
                          rareChance={20}
                          id="about-bodies"
                          rareItemLibido={10}/>
                        <Line />
                        <ListItem label="Heads" count="16" original={4} rare={12} rareChance={20} id="about-heads" rareItemLibido={10} />
                        <Line />
                        <ListItem label="Mouths" count="20" original={18} rare={2} rareChance={5} id="about-mouths" rareItemLibido={15} />
                        <Line />
                        <ListItem label="Beards & Mustaches" count="10" original={0} rare={10} rareChance={30} id="about-facial-hair" rareItemLibido={8}/>
                        <Line />
                        <ListItem
                          label="Eyes"
                          count="23"
                          isAlt={true}
                          originalStyles={5}
                          originalVariations={12}
                          rare={2}
                          rareChance={5}
                          id="about-eyes"
                          rareItemLibido={15}/>
                        <Line />
                        <ListItem
                          label="Eyebrows"
                          count="15"
                          isAlt={true}
                          originalStyles={5}
                          originalVariations={10}
                          rare={0}
                          rareChance={0}
                          id="eyebrows"
                          rareItemLibido={null}/>
                        <Line />
                        <ListItem label="Hairs & Hats" count="32" original={15} rare={17} rareChance={20} id="about-hairs" rareItemLibido={10}/>
                        <Line />
                        <ListItem label="Glasses" count="12" original={0} rare={17} rareChance={10} id="about-glasses" rareItemLibido={12}/>
                      </List>
                    </ListWrapper>
                  </ContentWrapper>
                </Paper>
              </Container>
          </AboutWrapper>
        </AboutContainer>
    )
}

export default AboutSection
