import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";
import BadDadsABI from "../../abi.json";
import CONFIG from "../../config.json"
import { fetchData } from "../data/dataActions";


const CONTRACT_ADDRESS = CONFIG.CONTRACT_ADDRESS
const RPC = CONFIG.RPC
const web3 = new Web3(RPC);


const connectRequest = () => {
    return {
        type: "CONNECTION_REQUEST",
    };
};

const connectSuccess = (payload) => {
    return {
        type: "CONNECTION_SUCCESS",
        payload: payload,
    };
};

const connectFailed = (payload) => {
    return {
        type: "CONNECTION_FAILED",
        payload: payload,
    };
};

const updateAccountRequest = (payload) => {
    return {
        type: "UPDATE_ACCOUNT",
        payload: payload,
    };
};

export const updateAccount = (account) => {
    return async (dispatch) => {
      dispatch(updateAccountRequest({ account: account }));
      dispatch(fetchData(account));
    };
  };

export const connect = () => {
    return async (dispatch) => {
        dispatch(connectRequest())
        const { ethereum } = window
        
        const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
        
        if (!metamaskIsInstalled) {
            dispatch(connectFailed("Install Metamask."))
        } else {
            Web3EthContract.setProvider(ethereum);
            try {
                const accounts = await ethereum.request({
                    method: "eth_requestAccounts",
                })
                const networkId = await ethereum.request({
                    method: "net_version",
                  });
                
                if (networkId != CONFIG.NETWORK.ID) {
                    dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`))
                } else {

                    const SmartContractObj = new Web3EthContract(
                        BadDadsABI,
                        CONTRACT_ADDRESS
                      );
                    // dispatch(connectSuccess({account: accounts[0], smartContract: contract, web3: web3}))
                    dispatch(connectSuccess({account: accounts[0], smartContract: SmartContractObj, web3: web3}))

                    ethereum.on("accountsChanged", (accounts) => {
                        dispatch(updateAccount(accounts[0]));
                    });
                    ethereum.on("chainChanged", () => {
                        window.location.reload();
                    });
                }
            } catch (err) {
                console.log("Error: ", err)
                dispatch(connectFailed("Something went wrong."));
            }
        }
    }
}