import styled, { keyframes } from 'styled-components';
import bgImg from '../../assets/BG-Pattern-2.png'

export const HotDogContainer = styled.div`
    background-color: #92e8e3;
    background-image: url(${bgImg});
    background-repeat: repeat;
    background-size: 512px 512px;

    max-width: 100%;
    min-height: 100%;
    padding: 0 20px 50px 20px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`

export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
`

export const TitleWrapper = styled.div`
    text-align: center;
`

export const Title = styled.p`
    font-size: 60pt;
    font-family: Alfarn;
    color: #fffe66;
    text-shadow: 3px 3px #FF6363;

    @media screen and (max-width: 600px) {
        font-size: 40pt;
    }

    @media screen and (max-width: 450px) {
        font-size: 30pt;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    gap: 25px;

    @media screen and (max-width: 600px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`

export const ContentWrapper = styled.div`
    width: 100%;   
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    max-width: 700px;
`

export const LibidoWrapper = styled.div`
    width: 700px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 600px) {
        width: 80vw;
    }
`

export const RotatingDogContainer = styled.div`
    min-height: 100%;
    width: 300px;
    padding: 250px 50px 0 50px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

export const RotatingDogWrapper = styled.div`
    width: 100%;
`

const rotation = keyframes`
    from {transform: rotate(0deg)}
    to {transform: rotate(359deg)}
`

export const RotatingDog = styled.img`
    width: 100%;

    animation-name: ${rotation};
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;  
`

export const UniqueWrapper = styled.div`
    width: 700px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 600px) {
        width: 80vw;
    }
`