import styled from "styled-components";
import bgImg from '../../assets/BG-Pattern-2.png'

export const TeamContainer = styled.div`
    max-width: 100%;
    // background-color: #92e8e3;
    background-color: #FF6363;
    background-image: url(${bgImg});
    background-repeat: repeat;
    background-size: 512px 512px;

    min-height: 50vh;
    display: flex;
    flex-direction: row;
    padding: 20px 20px 20px 20px;
`

export const TeamWrapper = styled.div`
    width: 100%;
    background-color: #FF6363;
    // background-color: #25ccc3;
    // border: 2px solid #25ccc3;
    border: 2px solid white;
    border-radius: 30px;
    // box-shadow: 0 0 10px #25ccc3;
    box-shadow: 0 0 10px white;

    z-index: 1;

    position: relative;
`

export const TitleWrapper = styled.div`
    max-width: 100%;
    width: 100%;
    text-align: center;

    position: relative;

    z-index: 2;
`

export const Title = styled.p`
    font-size: 60pt;
    font-family: Alfarn;
    color: #fffe66;
    

    @media screen and (max-width: 600px) {
        font-size: 40pt;
    }

    @media screen and (max-width: 450px) {
        font-size: 30pt;
    }
`

export const TeamMembersWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 3;

    position: relative;
`

export const BGDadContainer = styled.div`
    position: absolute;
    right: 0px;
    bottom: 0px;

    width: 400px;
    max-height: 60%;
    z-index: 0;
    overflow: hidden;

    border-radius: 20px;

    @media screen and (max-width: 1081px) {
        max-height: 45%;
    }
    
    @media screen and (max-width: 741px) {
        max-height: 35%;
    }
`

export const BGDadWrapper = styled.div`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 100%;
`
    
export const BGDad = styled.img`
    height: 500px;
    opacity: 0.4;
    transform: rotate(-20deg);
        
    position: relative;
    right: 0;

    @media screen and (max-width: 1081px) {
        height: 600px;
    }
    
    @media screen and (max-width: 451px) {
        display: none;
    }
`

export const BGDadContainerLeft = styled.div`
    position: absolute;
    left: 0px;
    bottom: 0px;

    width: 400px;
    max-height: 60%;
    z-index: 0;
    overflow-y: hidden;
    overflow-x: visible;

    border-radius: 20px;
    

    @media screen and (max-width: 1081px) {
        max-height: 45%;
    }
    
    @media screen and (max-width: 741px) {
        max-height: 35%;
    }
`

export const BGDadWrapperLeft = styled.div`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 100%;
`
    
export const BGDadLeft = styled.img`
    height: 500px;
    opacity: 0.4;
    transform: rotate(20deg) scaleX(-1);

    position: relative;
    left: -75px;

    @media screen and (max-width: 1081px) {
        height: 600px;
        left: -175px;
    }
    
    @media screen and (max-width: 651px) {
        display: none;
    }
`


