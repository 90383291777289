// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (address) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      
       let walletIDs = await store
         .getState()
         .blockchain.smartContract.methods.walletOfOwner(address)
         .call();

      let paused = await store
         .getState()
         .blockchain.smartContract.methods.paused()
         .call();

      let revealed = await store
        .getState()
        .blockchain.smartContract.methods.revealed()
        .call()
      
      let maxMintAmount = await store
        .getState()
        .blockchain.smartContract.methods.maxMintAmount()
        .call()
      
      let maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.maxSupply()
        .call()

      dispatch(
        fetchDataSuccess({
          totalSupply,
          walletIDs,
          paused,
          revealed,
          maxMintAmount,
          maxSupply,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
