import React from 'react'
import {
  DetailsWrapper,
  AltDetailsWrapper,
  OriginalCountWrapper,
  RareCountWrapper,
  Count,
  Text,
  AltTextWrapper,
  AltCount,
  AltText,
  OriginalAltCount,
  OriginalCount,
  OriginalText,
  OriginalAltText,
} from './DetailsElements'


const Details = (props) => {
  const originalCount = props.isAlt ? props.originalStyles + props.originalVariations : props.original
  const altTextStyle = {display: props.isAlt ? '' : 'none'}
  const newStyle = {color: "#ff9b85"}

  return(
    <DetailsWrapper id={`details-wrapper-${props.label}`}>
      <OriginalCountWrapper>
        <OriginalCount>{originalCount}</OriginalCount>
        <OriginalText>&nbsp;Original</OriginalText>
        <AltTextWrapper style={altTextStyle}>
          <OriginalAltText>(</OriginalAltText>
          <OriginalAltCount>{props.originalStyles}</OriginalAltCount>
          <OriginalAltText>&nbsp;Styles +&nbsp;</OriginalAltText>
          <OriginalAltCount>{props.originalVariations}</OriginalAltCount>
          <OriginalAltText>&nbsp;Variations)</OriginalAltText>
        </AltTextWrapper>
      </OriginalCountWrapper>
      <RareCountWrapper>
        <Count>{props.rare}</Count>
        <Text>&nbsp;Rare</Text>
        <AltTextWrapper>
          <AltText>(Chance:&nbsp;</AltText>
          <AltCount>{props.rareChance}%</AltCount>
          <AltText>)</AltText>
        </AltTextWrapper>
      </RareCountWrapper>
    </DetailsWrapper>
  )
}

export default Details
