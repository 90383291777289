
import BadDadsABI from "../abi.json";
import Web3 from 'web3'
import CONFIG from '../config.json'

const CONTRACT_ADDRESS = CONFIG.CONTRACT_ADDRESS
const RPC = CONFIG.RPC

const web3 = new Web3(RPC);
const contract = new web3.eth.Contract(BadDadsABI, CONTRACT_ADDRESS)


export const getTotalSupply = async () => {
    const totalSupply = await contract.methods.totalSupply().call()
    return totalSupply
}

export const getMaxSupply = async () => {
    const maxSupply = await contract.methods.maxSupply().call()
    return maxSupply
}

export const getTotalAndMaxSupply = async () => {
    const total = await getTotalSupply()
    const max = await getMaxSupply()
    return {total, max}
}

