import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import defaultMetdata from './defaultMetadata.json'
import notRevealedMetdata from './notRevealedMetadata.json'
import NotRevealedImg from '../../assets/NotRevealed.png'
import Attribute from './Attribute'
import AttributeTitle from './AttributeTitle';
import CONFIG from "../../config.json"


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const defaultAttributes = {
    background: {},
    body: {},
    head: {},
    mouth: {},
    facialHair: {},
    eyebrows: {},
    eyes: {},
    hair: {},
    glasses: {},
}

const notRevealedImgURL = '../../assets/NotRevealed.png'

const NFTCard = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [metadata, setMetadata] = useState({defaultMetdata})
    const [attributes, setAttributes] = useState(defaultAttributes)
    const [imgUrl, setImgUrl] = useState(notRevealedImgURL)


    const ipfsBaseURL = CONFIG.IPFS_BASE_URL

    
    
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const updateMetadata = async () => {

        let metadata
        if (props.data.revealed) {
            const metadataURL = `${ipfsBaseURL}/${process.env.REACT_APP_METADATA_CID}/${props.index}.json`
            const response = await fetch(metadataURL)
            metadata = await response.json()
        } else {
            metadata = notRevealedMetdata
        }

        // Formating Layer Object Names
        let formattedAttributes = metadata.attributes.map((attrObj) => {
            if (attrObj.name === "Facial-Hair_None") {
                return {...attrObj, name: "None"}
            } else if (attrObj.name === "Glasses_None") {
                return {...attrObj, name: "None"}
            } else { 
                let newName = attrObj.name.replaceAll("_", " ")
                newName = newName.replace("-", " ")
                return {...attrObj, name: newName}
            }
        })
        metadata.attributes = formattedAttributes

        // Capitalizing piItem
        switch (metadata.piItem) {
            case "melee":
                metadata.piItem = "Melee"
                break;
            case "gun":
                metadata.piItem = "Gun"
                break
            case "item":
                metadata.piItem = "Item"
                break
            default:
                
        }


        updateAttributes(metadata.attributes)

        setMetadata(metadata)
    }

    const updateAttributes = (attributeArray) => {
        const newAttributes = {}
        attributeArray.forEach((attrObj) => {
            if (attrObj.layer === "facial hair") {
                newAttributes.facialHair = {name: attrObj.name, rarity: attrObj.rarity}
            } else {
                newAttributes[attrObj.layer] = {name: attrObj.name, rarity: attrObj.rarity}
            }
        })

        setAttributes(newAttributes)
    }
    
    useEffect(() => {
        
        if (props.data.revealed) {
            setImgUrl(`${ipfsBaseURL}/${process.env.REACT_APP_IMAGE_CID}/${props.index}.png`)
        } else {
            setImgUrl(NotRevealedImg)
        }
    }, [props.data.revealed, metadata])

    useEffect(() => {
        updateMetadata()
    }, [props.data])

    return(
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: "#FF6363", color: "#FFFDA2" }} aria-label="nft-id">
                    {props.index}
                </Avatar>
                }
                title={metadata.name}
                titleTypographyProps={{fontSize: "large", color: "#FF6363", fontWeight: "bold", fontFamily: "Alfarn"}}
            />
            <CardMedia
                height="345"
                component="img"
                image={imgUrl}
                alt="bad-dad-image"
            />
            <CardActions disableSpacing>
                <Typography variant="h5" align="center">Libido: {metadata.libido}</Typography>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <AttributeTitle /><br/>
                    <Attribute name="Job" value={metadata.job} rarity=""/>
                    <Attribute name="Hobby" value={metadata.hobby} rarity=""/>
                    <Attribute name="PI Item" value={metadata.piItem} rarity=""/>
                    <Attribute name="Background" value={attributes.background.name} rarity={attributes.background.rarity} />
                    <Attribute name="Body" value={attributes.body.name} rarity={attributes.body.rarity} />
                    <Attribute name="Head" value={attributes.head.name} rarity={attributes.head.rarity} />
                    <Attribute name="Mouth" value={attributes.mouth.name} rarity={attributes.mouth.rarity} />
                    <Attribute name="Facial Hair" value={attributes.facialHair.name} rarity={attributes.facialHair.rarity} />
                    <Attribute name="Eyebrows" value={attributes.eyebrows.name} rarity={attributes.eyebrows.rarity} />
                    <Attribute name="Eyes" value={attributes.eyes.name} rarity={attributes.eyes.rarity} />
                    <Attribute name="Hair" value={attributes.hair.name} rarity={attributes.hair.rarity} />
                    <Attribute name="Glasses" value={attributes.glasses.name} rarity={attributes.glasses.rarity} />
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default NFTCard







