import React from 'react'
import {
    EmptyGalleryContainer,
    MessageWrapper,
    Message
} from './EmptyGalleryComponents'

const EmptyGallery = (props) => {
    return(
        <EmptyGalleryContainer>
            <MessageWrapper>
                <Message>{props.message}</Message>
            </MessageWrapper>
        </EmptyGalleryContainer>
    )
}

export default EmptyGallery