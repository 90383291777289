import styled from 'styled-components'

export const DetailsWrapper = styled.div`
    @media screen and (max-width: 710px) {
        text-align: center;
    };
`

export const AltDetailsWrapper = styled.div`

`

export const OriginalCountWrapper = styled.div`
`
    
export const RareCountWrapper = styled.div`
`

export const AltTextWrapper = styled.div`
    padding-left: 10px;
    @media screen and (max-width: 710px) {
        padding-left: 0;
    };
`

export const Count = styled.span`
    font-family: Righteous;
    font-size: 1.3em;
    color: #ff9b85;

    @media screen and (max-width: 600px) {
        font-size: 1.2em;
    };
    @media screen and (max-width: 415px) {
        font-size: 1.1em;
    };
    @media screen and (max-width: 300px) {
        font-size: 1.0em;
    };
`

export const Text = styled.span`
    font-size: 1.5em;    
    font-family: Righteous;    
    color: #ff9b85;

    @media screen and (max-width: 600px) {
        font-size: 1.3em;
    };
    @media screen and (max-width: 415px) {
        font-size: 1.2em;
    };
    @media screen and (max-width: 300px) {
        font-size: 1.1em;
    };
`

export const AltCount = styled.span`
    font-family: Righteous;
    font-size: 1.1em;
    color: #ff9b85;

    @media screen and (max-width: 600px) {
        font-size: 1em;
    };
`

export const AltText = styled.span`
    font-family: Righteous;    
    font-size: 1.1em;    
    color: #ff9b85;

    @media screen and (max-width: 600px) {
        font-size: 0.9em;
    };
`

export const OriginalCount = styled.span`
    font-family: Righteous;
    font-size: 1.3em;
    color: #ffd97d;

    @media screen and (max-width: 600px) {
        font-size: 1.2em;
    };
    @media screen and (max-width: 415px) {
        font-size: 1.1em;
    };
    @media screen and (max-width: 300px) {
        font-size: 1.0em;
    };
`

export const OriginalText = styled.span`
    font-size: 1.5em;    
    font-family: Righteous;    
    color: #ffd97d;

    @media screen and (max-width: 600px) {
        font-size: 1.3em;
    };
    @media screen and (max-width: 415px) {
        font-size: 1.2em;
    };
    @media screen and (max-width: 300px) {
        font-size: 1.1em;
    };
`

export const OriginalAltCount = styled.span`
    color: #ffd97d;
    font-family: Righteous;
    font-size: 1.1em;

    @media screen and (max-width: 600px) {
        font-size: 1em;
    };
`

export const OriginalAltText = styled.span`
    font-size: 1.1em;    
    font-family: Righteous;    
    color: #ffd97d;

    @media screen and (max-width: 600px) {
        font-size: 0.9em;
    };
`
