import styled from 'styled-components'
import bgImg from '../../assets/Hotdog-med.png'

export const RoadmapContainer = styled.div`
    background-color: #92e8e3;
    background-image: url(${bgImg});
    background-repeat: repeat;
    background-size: 75px 75px;
    max-width: 100%;
    min-height: 500px;
    padding: 30px 20px 40px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const RoadmapWrapper = styled.div`
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const RoadmapTitleWrapper = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const RoadmapTitle = styled.h2`
    font-size: 60pt;
    font-family: Alfarn;
    color: #fffe66;
    text-shadow: 3px 3px #FF6363;

    @media screen and (max-width: 600px) {
        font-size: 40pt;
    }

    @media screen and (max-width: 450px) {
        font-size: 30pt;
    }
`
export const RoadmapSubtitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`
export const RoadmapSubtitle = styled.p`
    font-size: 30pt;
    font-family: Alfarn;
    color: white;
`

export const ImgWrapper = styled.div`
    max-width: 900px;
`

export const Img = styled.img`
    width: 100%;
    object-fit: contain;
`


