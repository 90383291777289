import React, { useState, useEffect } from 'react'
import {FaBars, FaTwitter, FaDiscord} from 'react-icons/fa'
import {
  SidebarContainer, 
  Icon, 
  CloseIcon, 
  SidebarWrapper, 
  SidebarMenu, 
  SidebarLink,
  ConnectWrapper,
  ConnectButton,
  IconsWrapper,
} from './SidebarElements'
import { connect } from '../../redux/blockchain/blockchainActions'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../redux/data/dataActions'
import { ExternalLink } from '../ButtonElements'


const Sidebar = (props) => {
  const [connectBtnStyle, setConnectBtnStyle] = useState({display: ''})
  const dispatch = useDispatch()

  const scrollToId = (elementId) => {
    props.toggle()
    var element = document.getElementById(elementId)
    element.scrollIntoView()
  }

  const getData = () => {
    if (props.blockchain.account !== "" && props.blockchain.smartContract !== null) {
      dispatch(fetchData(props.blockchain.account));
    }
  }

  const connectToMetamask = (event) => {
    event.preventDefault()
    dispatch(connect())
    getData()
  }

  useEffect(() => {
    if (props.blockchain.smartContract === null) {
      setConnectBtnStyle({display: ""})
    } else {
      setConnectBtnStyle({display: 'none'})
    }
  }, [props.blockchain.account])

  return (
    <SidebarContainer isOpen={props.isOpen} onClick={props.toggle} id="sidebar-container">
      <Icon onClick={props.toggle} id="sidebar-icon">
        <CloseIcon />
      </Icon>
      <SidebarWrapper id="sidebar-wrapper">
        <SidebarMenu>
          <ConnectWrapper>
            <ConnectButton onClick={connectToMetamask} style={connectBtnStyle}>
              Connect
            </ConnectButton>
          </ConnectWrapper>
          <SidebarLink onClick={() => scrollToId("hero")}>
            BadDads
          </SidebarLink>
          <SidebarLink onClick={() => scrollToId("gallery")}>
            Gallery
          </SidebarLink>
          <SidebarLink onClick={() => scrollToId("about")}>
            About
          </SidebarLink>
          <SidebarLink onClick={() => scrollToId("team")}>
            Team
          </SidebarLink>
          <SidebarLink onClick={() => scrollToId("roadmap")}>
            Roadmap
          </SidebarLink>
          <IconsWrapper>
            <ExternalLink href="https://discord.gg/tkvEDsBj9w" alt="MintLink" target="_blank" rel="noreferrer">
              <FaDiscord size="2em" color="#fffe66" id="icon-discord" />
            </ExternalLink>
            <ExternalLink href="https://twitter.com/BadDadsNFT" alt="MintLink" target="_blank" rel="noreferrer">
              <FaTwitter size="2em" color="#fffe66"  id="icon-twitter" />
            </ExternalLink>
          </IconsWrapper>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
