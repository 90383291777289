import React, { useState, useEffect } from 'react'
import signGif from '../../assets/sign.gif'
import {
  SignContainer,
  SignWrapper,
  SignTextWrapper,
  SignTextContainer,
  SignText,
  SignSubText,
  ButtonWrapper,
  IncrementButton,
  SignButton,
} from './SignComponents'
import ProgressSpinner from './ProgressSpinner'


const Sign = (props) => {
  const [buttonRowStyle, setButtonRowStyle] = useState({display: 'none'})
  const [decreaseBtnStyle, setDecreaseBtnStyle] = useState({display: 'none'})
  const [increaseBtnStyle, setIncreaseBtnStyle] = useState({display: ''})


  const handleDecreaseClick = (event) => {
    event.preventDefault()
    if (props.mintAmount > 1) {
      props.handleMintAmtChange(props.mintAmount - 1)
    }
  }

  const handleIncreaseClick = (event) => {
    event.preventDefault()
    if (props.mintAmount < props.maxMintAmount) {
      props.handleMintAmtChange(props.mintAmount + 1)
    }
  }

  const handleButtonClick = (event) => {
    event.preventDefault()
    props.handleButtonClick()
  }

  useEffect(() => {
    if (props.blockchain.smartContract !== null && props.maxMintAmount > 0 && !props.data.paused) {
      setButtonRowStyle({display: ''})
    } else {
      setButtonRowStyle({display: 'none'})
    }
  }, [props.blockchain.smartContract, props.maxMintAmount])

  useEffect(() => {
    if (props.mintAmount === 1) {
      setDecreaseBtnStyle({visibility: 'hidden'})
      setIncreaseBtnStyle({visibility: 'visible'})
    } else if (Number(props.mintAmount) === Number(props.maxMintAmount)) {
      setDecreaseBtnStyle({visibility: 'visible'})
      setIncreaseBtnStyle({visibility: 'hidden'})
    } else {
      setDecreaseBtnStyle({visibility: 'visible'})
      setIncreaseBtnStyle({visibility: 'visible'})
    }
  }, [props.mintAmount, props.maxMintAmount])

  return(
    <SignContainer id="sign">
      <SignWrapper id="sign-wrapper" style={{backgroundImage: `url(${signGif})`}}>
          <SignTextContainer id="sign-text-container">
              <SignTextWrapper id="sign-text-wrapper">
                  {props.claimingNFT ? 
                    <ProgressSpinner />
                    :
                    <SignText className='sign' id="sign-text">
                        {props.text}
                    </SignText>
                  }
              </SignTextWrapper>
              <SignTextWrapper id="sign-text-wrapper-subtext">
                  <SignSubText className='sign' id="sign-text-2">
                      {props.subText}
                  </SignSubText>
              </SignTextWrapper>
              <SignTextWrapper id="sign-text-wrapper-3">
                  <SignSubText className='sign' id="sign-network-msg">
                      {props.networkMessage}
                  </SignSubText>
              </SignTextWrapper>
          </SignTextContainer>
      </SignWrapper>
      <ButtonWrapper style={buttonRowStyle} id="sign-button-wrapper">
        <IncrementButton onClick={handleDecreaseClick} style={decreaseBtnStyle} disabled={props.claimingNft ? 1 : 0}>-</IncrementButton>
        <SignButton onClick={handleButtonClick} disabled={props.claimingNft || props.data.paused  ? 1 : 0}>{props.buttonText}</SignButton>
        <IncrementButton onClick={handleIncreaseClick} style={increaseBtnStyle} disabled={props.claimingNft ? 1 : 0}>+</IncrementButton>
      </ButtonWrapper>
    </SignContainer>
  )
}

export default Sign
