import React from 'react'
import {
    GalleryContainer,
    GalleryWrapper,
    TitleWrapper,
    TitleRow,
    Title,
    ContentContainer,
    CardWrapper,
} from './GalleryComponents'
import EmptyGallery from './EmptyGallery'
import NFTCard from './Card'

 
const Gallery = (props) => {
    return(
        <GalleryContainer id="gallery" name="gallery">
            <GalleryWrapper id="gallery-wrapper">
                <TitleWrapper id="gallery-title-wrapper">
                    <TitleRow id="gallery-title-row">
                        <Title id="gallery-title">Gallery</Title>
                    </TitleRow>
                </TitleWrapper>
                {
                    props.nftIds.length > 0 ?
                        <ContentContainer id="gallery-content-container">
                            {props.nftIds.map((id, index) => (
                                <CardWrapper key={index} id={`card-wrapper-${id}`} >
                                    <NFTCard index={id} data={props.data}/>
                                </CardWrapper>
                            ))}
                        </ContentContainer>
                        :
                        <EmptyGallery message="No BadDads to Display" />
                }
            </GalleryWrapper>
        </GalleryContainer>
    )
}

export default Gallery