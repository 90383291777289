/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import '../../index.css'
import BgImg from '../../assets/hero-background.png'
import dadMain from '../../assets/hero-gif.gif'
import {
    HeroContainer,
    HeroRow,
    SubTitle,
    MainDadContainer,
    DadImg,
    HeroBgWrapper,
    HeroBg,
    MarketPlaceWrapper,
    MarketPlaceText,
} from './HeroComponents'
import Sign from './Sign'
import { useDispatch, useSelector } from 'react-redux'
import CONFIG from "../../config.json"
import { fetchData } from '../../redux/data/dataActions'
import { getMaxSupply, getTotalSupply } from '../../helpers/web3'


const defaultSubtext = "0.3 MOVR per mint"
const initialNetworkMessage = "Connect to the Dang Network"
const pausedMessage = "Minting is Paused"
const mintingOverMessage = "Minting is Over"



const Hero = (props) => {
    const [signText, setSignText] = useState("0 / 3000")
    const [signSubText, setSignSubtext] = useState(defaultSubtext)
    const [mintAmount, setMintAmount] = useState(1)
    const [networkMessage, setNetworkMessage] = useState(initialNetworkMessage)
    const [claimingNFT, setClaimingNFT] = useState(false)
    const [mintButtonText, setMintButtonText] = useState('')
    const [maxMintAmount, setMaxMintAmount] = useState(CONFIG.MAX_MINT_AMOUNT)
    const [totalSupply, setTotalSupply] = useState(0)
    const [maxSupply, setMaxSupply] = useState(CONFIG.MAX_SUPPLY)


    const dispatch = useDispatch()
    const data = useSelector((state) => state.data);
    
    const updateSupply = async () => {
        const max = await getMaxSupply()
        const total = await getTotalSupply()
        const remainingSupply = max - total

        setTotalSupply(total)
        setMaxSupply(max)
        setSignText(`${total} / ${max}`)
        
        if (remainingSupply < data.maxMintAmount) {
            setMaxMintAmount(remainingSupply)
        } else {
            setMaxMintAmount(data.maxMintAmount)
        }
    }

    const mintButtonAction = () => {
        claimNFTs()
    }

    const getMintButtonText = () => {
        if (claimingNFT) {
            return "BUSY"
        } else if (data.paused) {
            return "PAUSED"
        } else {
            return "MINT"
        }
    }

    const updateNetworkMessage = () => {
        
        if (props.blockchain.errorMsg !== "") {
            setNetworkMessage(props.blockchain.errorMsg)
        } else if (props.blockchain.smartContract === null) {
            setNetworkMessage(initialNetworkMessage)
        } else if (data.paused) {
            setNetworkMessage(pausedMessage)
        } else if (maxMintAmount === 0) {
            setNetworkMessage(mintingOverMessage)
        } else if (claimingNFT) {
            mintAmount === 1 ? setNetworkMessage(`Minting ${mintAmount} BadDad`) : setNetworkMessage(`Minting ${mintAmount} BadDads`)
        } else {
            mintAmount === 1 ? setNetworkMessage(`Mint ${mintAmount} BadDad`) : setNetworkMessage(`Mint ${mintAmount} BadDads`)
        }
    }

    const claimNFTs = () => {
        const cost = CONFIG.WEI_COST;
        const gasLimit = CONFIG.GAS_LIMIT;
        const totalCostWei = String(cost * mintAmount);
        const totalGasLimit = String(gasLimit * mintAmount);
        setNetworkMessage(`Minting your ${CONFIG.NFT_NAME}...`);
    
        setClaimingNFT(true);
        props.blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: props.blockchain.account,
                value: totalCostWei,
            })
            .then((receipt) => {
                console.log(receipt);
                setNetworkMessage(
                `${mintAmount} BadDads are yours!`
                );
                setClaimingNFT(false);
                dispatch(fetchData(props.blockchain.account));
            })
            .catch((err) => {
                console.log(err);
                setClaimingNFT(false);
                setNetworkMessage("Sorry, something went wrong please try again later.");
            })
    }

    useEffect(() => {
        updateNetworkMessage()
        setMintButtonText(getMintButtonText())
        updateSupply()
        
    }, [
        props.blockchain.account,
        props.blockchain.smartContract,
        data.paused,
        props.blockchain.errorMsg,
        data.maxSupply,
        mintAmount,
        maxMintAmount,
        claimingNFT
    ])

    useEffect(() => {
        updateSupply()
    }, [])

    return(
        <HeroContainer id="hero" name="hero">
            <HeroRow id="hero-row-1">
                <SubTitle>
                    3000 randomly generated Badass Dad NFTs Minting on the Moonriver blockchain
                </SubTitle>
            </HeroRow>
            <HeroRow id="hero-row-4">
                <MainDadContainer id="main-dad-container">
                    <DadImg src={dadMain} id="dad-img" alt="the-bad-dad" />
                </MainDadContainer>
                <Sign
                    text={signText}
                    subText={signSubText}
                    blockchain={props.blockchain}
                    data={data}
                    networkMessage={networkMessage}
                    mintAmount={mintAmount}
                    handleMintAmtChange={setMintAmount}
                    buttonText={mintButtonText}
                    handleButtonClick={mintButtonAction}
                    claimingNFT={claimingNFT}
                    maxMintAmount={maxMintAmount}
                    handleUpdateSupply={updateSupply}
                />
            </HeroRow>
            <HeroRow id="hero-row-2">
                <SubTitle altColor={true}>
                    Minting starts March 26 2:00 PM UTC
                </SubTitle>
            </HeroRow>
            <HeroRow id="hero-row-3">
                <MarketPlaceWrapper href="https://moonbeanstoken.com/#/collections/baddads" alt="marketplace" target="_blank" rel="noreferrer">
                    <MarketPlaceText>
                        Trade on Moonbeans Marketplace
                    </MarketPlaceText>
                </MarketPlaceWrapper>
            </HeroRow>
            <HeroBgWrapper>
                <HeroBg src={BgImg} />
            </HeroBgWrapper>
        </HeroContainer>
    )
}

export default Hero
