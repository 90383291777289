import React from 'react'
import {
    TeamContainer,
    TeamWrapper,
    TitleWrapper,
    Title,
    TeamMembersWrapper,
    BGDadContainer,
    BGDadWrapper,
    BGDad,
    BGDadContainerLeft,
    BGDadWrapperLeft,
    BGDadLeft,
} from './TeamElements'
import TeamMemberCard from './TeamMemberCard'
import profile1 from '../../assets/Profile1.png'
import profile2 from '../../assets/Profile2.png'
import profile3 from '../../assets/Profile3.png'
import dadImg from '../../assets/BG-Dad.png'
import dadImgLeft from '../../assets/BG-Dad2.png'

const Team = () => {
    return(
        <TeamContainer id="team" name="team">
            <TeamWrapper id="team-wrapper">
                <TitleWrapper id="team-title-wrapper">
                    <Title id="team-title" >Team</Title>
                </TitleWrapper>
                <TeamMembersWrapper id="team-members-wrapper" >
                    <TeamMemberCard image={profile1} tag="@DueyNFT" title="Artist / Coder" link="https://twitter.com/DueyNFT" />
                    <TeamMemberCard image={profile2} tag="@CryptoVortex" title="Coder" link="https://twitter.com/fsg_dev" />
                    <TeamMemberCard image={profile3} tag="@Swackdaddy" title="Social Media" link="https://twitter.com/Swackdaddy" />
                </TeamMembersWrapper>
                <BGDadContainer id="bg-dad-container">
                    <BGDadWrapper id="bg-dad-wrapper">
                        <BGDad src={dadImg} id="bg-dad-img" alt="bg-dad" />
                    </BGDadWrapper>
                </BGDadContainer>
                <BGDadContainerLeft id="bg-dad-container-left">
                    <BGDadWrapperLeft id="bg-dad-wrapper-left">
                        <BGDadLeft src={dadImgLeft} id="bg-dad-img-left" alt="bg-dad-left" />
                    </BGDadWrapperLeft>
                </BGDadContainerLeft>
            </TeamWrapper>
        </TeamContainer>
    )
}

export default Team