import styled from "styled-components";
import bg from '../../assets/hero-background.png'

export const HeroContainer = styled.div`
  background-color: #92e8e3;

  width: 100%;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 75px 0px 0px; 0px;

  @media screen and (max-width: 1350px) {
      padding: 100px 20px 0 20px;
  };

  @media screen and (max-width: 1140px) {
      padding: 150px 0 0 0;
  };

  @media screen and (max-width: 971px) {
      min-height: 110vh;
      padding: 50px 0 0 0;
  };

  @media screen and (max-width: 971px) {
      min-height: 120vh;
      padding: 50px 0 0 0;
  };
  
  @media screen and (max-width: 768px) {
      min-height: 120vh;
      padding: 50px 0 0 0;
  };
  
  @media screen and (max-width: 481px) {
      min-height: 90vh;
      padding: 100px 0 0 0;
  };
`

export const HeroRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 400px);
  padding: 0 40px 0 40px;

  @media screen and (max-width: 1574px) {
      height: calc(100% - 300px);
  };

  @media screen and (max-width: 1164px) {
      height: calc(100% - 250px);
  };

  @media screen and (max-width: 971px) {
      height: calc(100% - 200px);
      padding: 0 100px 0 100px;
  };

  @media screen and (max-width: 768px) {
      height: calc(100% - 150px);
  };

  @media screen and (max-width: 583px) {
      height: calc(100% - 125px);
  };

  @media screen and (max-width: 481px) {
      height: calc(100% - 100px);
  };

  @media screen and (max-width: 370px) {
      height: calc(100% - 75px);
  };

  @media screen and (max-width: 270px) {
      height: calc(100% - 60px);
  };
`

export const MainDadContainer = styled.div`
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 100px;
    padding: 5px 5px 5px 5px;

    @media screen and (max-width: 1140px) {
        margin-right: 0;
    };

    @media screen and (max-width: 768px) {
        justify-content: flex-end;
    };
`

export const DadImg = styled.img`
    width: 100%;
    border-radius: 15px;
    width: 300px;
    z-index: 4;

    @media screen and (max-width: 971px) {
        width: 250px;
    };

    @media screen and (max-width: 481px) {
        max-width: 200px;
    };
`

export const GenericButton = styled.button`
  width: 100%;
  height: 100%;
`

export const SubTitle = styled.h1`
    // font-size: 1.6em;
    font-family: Righteous;
    color: ${ ({ altColor }) => (altColor ? '#ff6363' : '#fffe66' )};
    text-shadow: ${ ({ altColor }) => (altColor ? '2px 2px #fffe66' : '2px 2px #ff6363')};
    text-wrap: wrap;
    text-align: center;

    @media screen and (max-width: 481px) {
        font-size: 1em;
        text-shadow: ${ ({ altColor }) => (altColor ? '1px 1px #fffe66' : '2px 2px #ff6363')};
    };
`

export const HeroBgWrapper = styled.div`
    width: 100%;
    padding: 0 0 0 0;
`

export const HeroBg = styled.img`
    width: 100%;
    object-fit: contain;
`

export const MarketPlaceWrapper = styled.a`
    text-decoration: none;
    // margin: 20px 0 0 0;
`

export const MarketPlaceText = styled.h2`
    color: #ff6363;
    text-shadow: 2px 2px #fffe66;
    font-family: Righteous;
    text-align: center;
    @media screen and (max-width: 481px) {
        font-size: 0.8em;
        text-shadow: 1px 1px #fffe66;
    };
`