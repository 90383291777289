import styled from 'styled-components'


export const ListItemWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;

  margin: 10px 0 10px 0;
  
  @media screen and (max-width: 600px) {
    gap: 5px;
  };
`

export const CountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Count = styled.p`
  font-size: 2.2em;
  font-weight: bold;
  min-width: 60px;
  font-family: Alfarn;
  color: #ff9b85;

  @media screen and (max-width: 997px) {
    min-width: 0px;
  };

  @media screen and (max-width: 600px) {
    font-size: 1.5em;
  };
  @media screen and (max-width: 415px) {
    font-size: 1.3em;
  };
  @media screen and (max-width: 300px) {
    font-size: 1.1em;
  };
`
  
export const LabelWrapper = styled.div`  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Label = styled.p`
  font-size: 1.8em;
  font-family: Alfarn;

  color: #ffd97d;
  text-shadow: 2px 2px #ff9b85;

  overflow-wrap: anywhere;

  @media screen and (max-width: 600px) {
    font-size: 1.4em;
  };
  @media screen and (max-width: 415px) {
    font-size: 1.2em;
  };
  @media screen and (max-width: 300px) {
    font-size: 1.1em;
  };
`

export const TextContainer = styled.div`
  min-width: 350px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media screen and (max-width: 714px) {
    justify-content: center;
  };
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  min-width: 500px;

  @media screen and (max-width: 997px) {
    justify-content: center;
  };

  @media screen and (max-width: 415px) {
    min-width: 200px;
  };
  @media screen and (max-width: 300px) {
    min-width: 175px;
    // max-width: 100%;
  };
`

export const LibidoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 275 px;

`

export const LibidoWrapper = styled.div`
  align-items: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justiy-content: center;

`

export const LibidoText = styled.span`
  font-size: 1.6em;    
  font-family: Righteous;    
  color: #ffd97d;
  
  @media screen and (max-width: 600px) {
    font-size: 1.3em;
  };
  
  @media screen and (max-width: 300px) {
    font-size: 1.1em;
  };
`

export const LibidoCount = styled.span`
  font-size: 1.5em;    
  font-family: Righteous;    
  color: #ff9b85;
  
  @media screen and (max-width: 600px) {
    font-size: 1.3em;
  };
  
  @media screen and (max-width: 300px) {
    font-size: 1.1em;
  };
`

