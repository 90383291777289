import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dotenv from 'dotenv'

import { fetchData } from '../redux/data/dataActions'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Hero from '../components/HeroSection'
import HotdogSection from '../components/HotdogSection'
import RoadmapSection from '../components/RoadmapSection'
import Team from '../components/Team'
import Gallery from '../components/Gallery'
import AboutSection from '../components/AboutSection'

dotenv.config()

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };
    
    useEffect(() => {
        getData();
    }, [blockchain.account, blockchain.smartContract]);


    return(
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} blockchain={blockchain} data={data}  />
            <Navbar blockchain={blockchain} toggle={toggle} data={data} />
            <Hero blockchain={blockchain} data={data} getData={getData} />
            <Gallery nftIds={data.walletIDs} blockchain={blockchain} data={data}/>
            <AboutSection />
            <HotdogSection />
            <Team />
            <RoadmapSection />
        </>
    )
}

export default Home