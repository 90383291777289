import React from 'react'
import {
  ListItemWrapper,
  LabelContainer,
  CountWrapper,
  Count,
  LabelWrapper,
  Label,
  TextContainer,
  LibidoContainer,
  LibidoWrapper,
  LibidoText,
  LibidoCount,
} from './ListItemElements'

import Details from './Details'


const ListItem = (props) => {
  const rareItemLibidoStr = "Libido Modifier:"
  const rareItemLibitoAmt = props.rareItemLibido ? `${props.rareItemLibido}` : "-"

  return(
    <ListItemWrapper id={`list-item-wrapper-${props.label}`}>
      <LabelContainer id={`label-container-${props.label}`}>
        <CountWrapper id={`count-wrapper-${props.label}`}>
          <Count>
            {props.count}
          </Count>
        </CountWrapper>
        <LabelWrapper id={`label-wrapper-${props.label}`}>
          <Label>
            {props.label}
          </Label>
        </LabelWrapper>
      </LabelContainer>
      <TextContainer id={`text-container-${props.label}`}>
        <Details
          label={props.label}
          isAlt={props.isAlt}
          original={props.original}
          rare={props.rare}
          originalStyles={props.originalStyles}
          originalVariations={props.originalVariations}
          rareChance={props.rareChance}
        />
      </TextContainer>
      <LibidoContainer id={`libido-container-${props.label}`}>
        <LibidoWrapper id={`libido-wrapper-${props.label}`}>
          <LibidoText>{rareItemLibidoStr}&nbsp;</LibidoText>
          <LibidoCount>{rareItemLibitoAmt}</LibidoCount>
        </LibidoWrapper>
      </LibidoContainer>
    </ListItemWrapper>
  )
}

export default ListItem
