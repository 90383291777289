import React from 'react'
import LibidoCard from './LibidoCard'
import UniqueCard from './UniqueCard'
import {
    HotDogContainer,
    LibidoWrapper,
    TitleRow,
    ContentContainer,
    TitleWrapper,
    Title,
    ContentWrapper,
    UniqueWrapper,
} from './HotdogElements'
import StatsPanel from './StatsPanel'
import UniquePanel from './UniquePanel'

const DetailsSection = () => {
    return(
        <HotDogContainer id="hotdogs" name="hotdogs" title="hotdogs">
            <LibidoWrapper id="libido-wrapper">
                <TitleRow id="libido-title-row">
                    <TitleWrapper id="libido-title-wrapper">
                        <Title id="libido-title">Libido</Title>
                    </TitleWrapper>
                </TitleRow>
                <ContentContainer id="libido-content-container">
                    <ContentWrapper id="libido-content-wrapper-card">
                        <LibidoCard id="libido-card" />
                    </ContentWrapper>
                    <ContentWrapper id="libido-content-wrapper-panel">
                        <StatsPanel id="libido-stats-panel" />
                    </ContentWrapper>
                </ContentContainer>
            </LibidoWrapper>
            <UniqueWrapper>
                <TitleRow id="unique-title-row">
                    <TitleWrapper id="unique-title-wrapper">
                        <Title id="unique-title">Mega Dads</Title>
                    </TitleWrapper>
                </TitleRow>
                <ContentContainer id="unique-content-container">
                    <ContentWrapper id="unique-content-wrapper-card">
                        <UniqueCard id="unique-card" />
                    </ContentWrapper>
                    <ContentWrapper id="unique-content-wrapper-panel">
                        <UniquePanel id="unique-panel" />
                    </ContentWrapper>
                </ContentContainer>
            </UniqueWrapper>
        </HotDogContainer>
    )
}

export default DetailsSection