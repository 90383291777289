import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
  },
});

let id = 0;
const createData = (libido, count, percentile) => {
  id += 1;
  return { id, libido, count, percentile };
}

const rows = [
  createData(0, 712, "23.73"),
  createData(8, 300, "33.73"),
  createData(10, 715, "57.57"),
  createData(12, 68, "59.83"),
  createData(15, 67, "62.07"),
  createData(18, 287, "71.63"),
  createData(20, 274, "80.77"),
  createData(22, 80, "83.43"),
  createData(23, 25, "84.27"),
  createData(25, 75, "86.77"),
  createData(27, 5, "86.93"),
  createData(28, 107, "90.50"),
  createData(30, 76, "93.03"),
  createData(32, 32, "94.10"),
  createData(33, 28, "95.03"),
  createData(35, 36, "96.23"),
  createData(37, 8, "96.50"),
  createData(38, 23, "97.27"),
  createData(40, 20, "97.93"),
  createData(42, 10, "98.27"),
  createData(43, 14, "98.73"),
  createData(45, 10, "99.07"),
  createData(47, 4, "99.20"),
  createData(48, 3, "99.30"),
  createData(50, 7, "99.53"),
  createData(52, 1, "99.57"),
  createData(53, 1, "99.60"),
  createData(55, 1, "99.63"),
  createData(72, 1, "99.67"),
  createData(100, 10, "100"),
];

const StatsTable = (props) => {
  const { classes } = props;
  const headerStyle = {fontFamily: "Alfarn", color: "#fffe66", textShadow: "1px 1px #ff6363", fontSize: "1.2em"}
  const cellStyle = {color: "#FF6363", fontWeight: "bold"}

  return (
    <Paper className={classes.root} style={{backgroundColor: "#92e8e3"}}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={headerStyle} variant="head">Libido</TableCell>
            <TableCell align="right" style={headerStyle}>Count</TableCell>
            <TableCell align="right" style={headerStyle}>Percentile</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row" style={cellStyle}>
                {row.libido}
              </TableCell>
              <TableCell align="right" style={cellStyle}>{row.count}</TableCell>
              <TableCell align="right" style={cellStyle}>{row.percentile}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

StatsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatsTable);