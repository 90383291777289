import React from 'react'
import { 
    AttrTitle,
    AttrRow,
    AttrValue,
} from './CardElements'

const Attribute = (props) => {
    const getStyle = () => {
        switch (props.rarity) {
            case "original":
                return {color: "black"}
            case "rare":
                return {color: "#FF6363", fontWeight: "bold"}
            case "unique":
                return {color: "gold", fontWeight: "bold"}
            default:
                return {color: "black"}
        }
    }

    const style = getStyle()
    return(
        <AttrRow>
            <AttrTitle>{props.name}:</AttrTitle>
            <AttrValue style={style}>{props.value}</AttrValue>
        </AttrRow>
    )
}

export default Attribute