import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const Nav = styled.div`
  background: #92e8e3;
  align-items: center;
  font-size: 1rem;
  position: relative;
  top: 0;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 1050px) {
    background: #ff6363;
  }

  @media screen and (max-width: 960 px) {
    transition: 0.8s all ease;
  }

  @media screen and (max-width: 480px) {
    position: sticky;
    top: -1px;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  height: 60px;
  z-index: 1;
  padding: 0 24px 0 24px;
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`

export const NavLogo = styled.div`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1050px) {
    display: block;
    position: absolute;
    top: -6px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    // color: #fff;
    // color: #ff6363;
    color: #fffe66;
  }
`

export const NavItem = styled.li`
  height: 80px;
  letter-spacing: 2px;;
`

export const NavLinks = styled.div`
  color: #fffe66;
  text-shadow: 1px 1px #ff6363;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 1rem;
  font-family: Alfarn;
  margin: 0 10px 0 10px;
`

export const NavExternalLink = styled.p`
  color: ${ ({ altColor }) => (altColor ? 'yellow' : '#27c0a1' )};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0 10px 0 10px;
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`

export const NavBtnLink = styled.div`
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`

export const NavItems = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  flex-grow: 10;
`

export const NavTitleWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding-top: 5px;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`

export const NavTitle = styled.h1`
  font-family: Alfarn;
  color: #fffe66;
  text-shadow: 3px 3px #ff6363;
  font-size: 2em;

  @media screen and (max-width: 1050px) {
    display: none;
  }
`

export const TitleImg = styled.img`
  content-fit: contain;
  height: 80%;
`

export const HotDogIconWrapper = styled.div`
  height: 100%;
  margin: 0 10px 0 20px;
  width: 28px;
`

export const HotDogIcon = styled.img`
  height: 100%;
  object-fit: contain;
`

export const ConnectWrapper = styled.div`
  height: 80px;

  display: flex;
  flex-direction: row;
  jusity-content: center;
  align-items: center;

`

export const ConnectButton = styled.div`

  font-size: 1rem;
  font-family: Alfarn;
  color: #FFFDA2;
  background-color: #FF6363;

  border-radius: 25px;
  border: none;
  margin: 0 15px 0 15px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 50%;
  margin: 0 10px 0 10px;
`