import styled from 'styled-components'
import bgImg from '../../assets/Hotdog-med.png'

export const AboutContainer = styled.div`
    background-color: #FF6363;
    background-image: url(${bgImg});
    background-repeat: repeat;
    background-size: 100px 100px;
    max-width: 100%;

    min-height: 500px;
    display: flex;
    flex-direction: column;
    padding: 75px 20px 75px 20px;
`

export const AboutWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const ContentWrapper = styled.div`
  width: 100%,
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 10px 10px 10px 10px;
`

export const ListWrapper = styled.div`
  width: 100%;

  padding: 0 10px 0 20px;
`

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`

export const TitleWrapper = styled.div`
    max-width: 100%;
    width: 100%;
    text-align: center;

    position: relative;

    z-index: 2;
`

export const Title = styled.p`
    font-size: 60pt;
    font-family: Alfarn;
    color: #fffe66;
    text-shadow: 3px 3px #FF6363;

    @media screen and (max-width: 600px) {
        font-size: 40pt;
    }

    @media screen and (max-width: 450px) {
        font-size: 30pt;
    }
`
