import styled from 'styled-components'

export const SignContainer = styled.div`
  margin-left: 100px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 1016px) {
      margin-left: 0;
  };

  
  
  @media screen and (max-width: 322px) {
    align-items: center;
};
`

export const SignWrapper = styled.div`
  width: 500px;

  display: flex;
  flex-direction: row;
  align-items: end;


  padding: 40px 35px 40px 35px;

  background-size: 100% 100%;
  background-repeat: no-repeat;



  @media screen and (max-width: 768px) {
      justify-content: flex-end;
      width: 400px;
  };
  
  @media screen and (max-width: 560px) {
      width: 325px;
  };
  
  @media screen and (max-width: 322px) {
      width: 270px;
      height: 220px;
  };
`

export const SignTextContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`

export const SignTextWrapper = styled.div`
  width: 100%;
  height: 50%;
`

export const SignText = styled.p`
  text-align: center;
  font-size: 30pt;

  @media screen and (max-width: 768px) {
    font-size: 20pt;
  };
  
  @media screen and (max-width: 560px) {
    font-size: 15pt;
  };
`

export const SignSubText = styled.p`
  text-align: center;
  font-size: 20pt;
  overflow-wrap: break-word;


  @media screen and (max-width: 768px) {
    font-size: 15pt;
  };
  
  @media screen and (max-width: 560px) {
    font-size: 10pt;
  };
`

export const SignNetworkText = styled.p`
  text-align: center;
  font-size: 15pt;
  overflow-wrap: break-word;
  


  @media screen and (max-width: 768px) {
    font-size: 15pt;
  };
  
  @media screen and (max-width: 560px) {
    font-size: 10pt;
  };
`

export const ButtonWrapper = styled.div`
  height: 75px;
  width: 100%;

  padding: 0 0 0 0;
  margin-top: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 322px) {
      width: 90%;
  };
`

export const IncrementButton = styled.button`
  width: 40px;
  height: 40px;

  background-size: 100% 100%;
  background-repeat: no-repeat;

  border-radius: 10px;
  border: none;

  font-size: 1.5rem;
  font-weight: bold;
  background-color: #FFFDA2;
  color: #FF6363;

  // border-style: solid;
  // border-color: blue;
`

export const SignButton = styled.button`
  width: 175px;
  height: 60px;

  font-size: 1rem;
  font-family: Alfarn;
  color: #FFFDA2;
  background-color: #FF6363;

  border-radius: 25px;
  border: none;
  margin: 0 15px 0 15px;
`
