import React from 'react'
import { 
    RarityRow,
    RarityTitle,
    RarityValues
} from './CardElements'

const AttributeTitle = () => {
    return(
        <RarityRow>
            <RarityTitle>Rarity:&nbsp;&nbsp;</RarityTitle>
            <RarityValues>
                <span>Original</span>&nbsp;|&nbsp;
                <span style={{color: "#FF6363", fontWeight: "bold"}}>Rare</span>&nbsp;|&nbsp;
                <span style={{color: "gold", fontWeight: "bold"}}>Unique</span>
            </RarityValues>
        </RarityRow>
    )
}

export default AttributeTitle