import styled from "styled-components";

export const CardContainer = styled.div`
    max-height: 500px;
    width: 300px;
    padding: 0 20px 0 20px;
    margin: 0 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
        font-size: 1.5em;
        margin: 0 20px 20px 20px;
    }

    @media screen and (max-width: 450px) {
        font-size: 1em;
        margin: 0 20px 10px 20px;
    }
`

export const CardWrapper = styled.div`
    // max-width: 100%;
    // max-height: 100%;
`

export const ImgWrapper = styled.div`
    // max-width: 100%;
`

export const Img = styled.img`
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
`

export const TagWrapper = styled.div`
    text-align: center;
`

export const Tag = styled.p`
    color: white;
    font-size: 2em;
    font-family: Righteous;

    @media screen and (max-width: 600px) {
        font-size: 1.5em;
    }

    @media screen and (max-width: 450px) {
        font-size: 1em;
    }
    
`

export const TitleWrapper = styled.div`
    text-align: center;
`

export const Title = styled.p`
    color: white;
    font-size: 2em;
    font-family: Alfarn;
    color: #fffe66;

    @media screen and (max-width: 600px) {
        font-size: 1.5em;
    }

    @media screen and (max-width: 450px) {
        font-size: 1em;
    }
    
`
