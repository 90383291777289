import styled from "styled-components";

export const AttrTitle = styled.span`
    font-weight: bold;
`

export const AttrRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const AttrValue = styled.span`
    text-align: right;
`

export const RarityRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const RarityTitle = styled.span`
    font-weight: bold;
`

export const RarityValues = styled.span`

`