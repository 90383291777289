import React from 'react'
import {
    Typography
} from '@mui/material'
import {
    Row,
} from './UniqueCardRowElements'

const styles = {
    name: {
        textShadow: "2px 2px #ff9b85", 
        fontFamily: "Alfarn",
        color:"#ffd97d",
        minWidth: "197px",
    },
    description: {
      color: "#ff9b85",
      fontFamily: "Righteous",
    },
  };

const UniqueCardRow = (props) => {
    return(
        <Row>
            <Typography variant="h5" component="h2" style={styles.name} gutterBottom>
                {props.name}:
            </Typography>
            <Typography variant="h6" component="h2" style={styles.description} gutterBottom>
                {props.description}
            </Typography>
        </Row>
    )
}

export default UniqueCardRow