import styled from 'styled-components';
import bgImg from '../../assets/BG-Pattern-2.png'

export const GalleryContainer = styled.div`
    background-color: #92e8e3;
    background-image: url(${bgImg});
    background-repeat: repeat;
    background-size: 512px 512px;
    max-width: 100%;

    min-height: 500px;
    display: flex;
    flex-direction: row;
    padding: 0 20px 50px 20px;
`

export const GalleryWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
`

export const TitleWrapper = styled.div`
    text-align: center;
`

export const Title = styled.p`
    font-size: 60pt;
    font-family: Alfarn;
    color: #fffe66;
    text-shadow: 3px 3px #FF6363;

    @media screen and (max-width: 600px) {
        font-size: 40pt;
    }

    @media screen and (max-width: 450px) {
        font-size: 30pt;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 40px;
    flex-wrap: wrap;
`

export const CardWrapper = styled.div`
`