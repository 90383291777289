import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Typography
} from '@mui/material'

const styles = {
  card: {
    minHeight: "175px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: "Righteous",
    color: "#ff6363",
  },
};

const UniqueCard = (props) => {
  const { classes } = props;

  return (
    <Card className={classes.card} style={styles.card}>
      <CardContent>
        <Typography variant="h5" component="h2" align="center" gutterBottom style={styles.title}>
          10 out of the 3000 BadDads are Hand Crafted with Unique Items. They have the highest Libido in the collection, 100.
        </Typography>
      </CardContent>
    </Card>
  );
}

UniqueCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UniqueCard);