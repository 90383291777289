import styled from 'styled-components'

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    
    width: 100%;
    
    flex-wrap: wrap;
`