import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Typography
  } from '@mui/material'

const styles = {
  card: {
    minHeight: "175px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontFamily: "Righteous",
    color: "#ff6363",
  },
};

const LibidoCard = (props) => {
  const { classes } = props;

  return (
    <Card className={classes.card} style={styles.card}>
      <CardContent>
        <Typography variant="h5" align="center" gutterBottom style={styles.text}>
          Libido is a BadDad's "Power" and is generated from Rare items. Libido will impact the breeding mechanic which will be implemented in the future.
        </Typography>
      </CardContent>
    </Card>
  );
}

LibidoCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LibidoCard);