import React, { useState, useEffect } from 'react'
import {FaBars, FaTwitter, FaDiscord} from 'react-icons/fa'
import { 
  Nav,
  NavbarContainer,
  NavTitleWrapper,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavTitle,
  HotDogIconWrapper,
  HotDogIcon,
  ConnectWrapper,
  ConnectButton,
} from './NavBarElements'
import HotDogImg from '../../assets/HotDog.png'
import acidFlaskLogo from '../../assets/AcidFlaskLogo.png'
import { ExternalLink } from '../ButtonElements'
import { connect } from '../../redux/blockchain/blockchainActions'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../redux/data/dataActions'


const Navbar = (props) => {
  const [connectBtnStyle, setConnectBtnStyle] = useState({display: ''})
  const dispatch = useDispatch()

  const scrollTo = (elementId) => {
    var element = document.getElementById(elementId)
    element.scrollIntoView();
  }

  const getData = () => {
    if (props.blockchain.account !== "" && props.blockchain.smartContract !== null) {
      dispatch(fetchData(props.blockchain.account));
    }
  }

  const connectToMetamask = (event) => {
    event.preventDefault()
    dispatch(connect())
    getData()
  }
  
  useEffect(() => {
    if (props.blockchain.smartContract === null) {
      setConnectBtnStyle({display: ""})
    } else {
      setConnectBtnStyle({display: 'none'})
    }
  }, [props.blockchain.account])

  return (
    <>
      <Nav id="nav">
        <NavbarContainer id="nav-container">
          <MobileIcon onClick={props.toggle} id="mobile-icon-wrapper">
            <FaBars id="mobile-icon" />
          </MobileIcon>
          <NavTitleWrapper id="nav-title-wrapper">
            <NavTitle id="nav-title">
              BadDads
            </NavTitle>
          </NavTitleWrapper>
          <NavMenu id="nav-menu">
            <ConnectWrapper id="connect-wrapper">
              <ConnectButton onClick={connectToMetamask} style={connectBtnStyle} id="connect-button">Connect</ConnectButton>
            </ConnectWrapper>
            <NavItem id="nav-item-gallery">
              <NavLinks onClick={() => scrollTo("gallery")} id="nav-link-gallery">Gallery</NavLinks>
            </NavItem>
            <NavItem id="nav-item-abouty">
              <NavLinks onClick={() => scrollTo("about")} id="nav-link-about">About</NavLinks>
            </NavItem>
            <NavItem id="nav-item-team">
              <NavLinks onClick={() => scrollTo("team")} id="nav-link-team">Team</NavLinks>
            </NavItem>
            <NavItem id="nav-item-roadmap">
              <NavLinks onClick={() => scrollTo("roadmap")} id="nav-link-roadmap">Roadmap</NavLinks>
            </NavItem>
            <HotDogIconWrapper id="icon-wrapper-discord">
              <ExternalLink href="https://discord.gg/tkvEDsBj9w" alt="MintLink" target="_blank" rel="noreferrer">
                <FaDiscord size="2em" color="#fffe66" id="icon-discord" />
              </ExternalLink>
            </HotDogIconWrapper>
            <HotDogIconWrapper id="icon-wrapper-twitter">
              <ExternalLink href="https://twitter.com/BadDadsNFT" alt="MintLink" target="_blank" rel="noreferrer">
                <FaTwitter size="2em" color="#fffe66"  id="icon-twitter" />
              </ExternalLink>
            </HotDogIconWrapper>
            <HotDogIconWrapper id="icon-wrapper-acl">
              <ExternalLink href="https://acidflasklabs.io" alt="MintLink" target="_blank" rel="noreferrer">
                <HotDogIcon src={acidFlaskLogo} id="icon-acl" />
              </ExternalLink>
            </HotDogIconWrapper>
            <HotDogIconWrapper id="icon-wrapper-hotdog">
              <HotDogIcon src={HotDogImg} id="icon-hotdog" />
            </HotDogIconWrapper>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
