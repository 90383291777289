import React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressSpinner = () => {

    const style = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "grey.500",
        padding: "50px 0 30px 0",
    }

  return (
    <Stack sx={style} spacing={2} direction="row">
      <CircularProgress color="warning" />
    </Stack>
  );
}

export default ProgressSpinner