import styled from "styled-components";

export const EmptyGalleryContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`
    
export const MessageWrapper = styled.div`

`
    
export const Message = styled.p`
    font-size: 30pt;    
    font-family: Alfarn;
    color: #fffe66;
    text-shadow: 3px 3px #FF6363;
    text-align: center;

    @media screen and (max-width: 600px) {
        font-size: 20pt;
    }
`
