import React from 'react';
import {
    CardContainer,
    CardWrapper,
    ImgWrapper,
    Img,
    TagWrapper,
    Tag,
    TitleWrapper,
    Title,
} from './TeamMemberCardElements'

const TeamMemberCard = (props) => {
    return(
        <CardContainer>
            <CardWrapper>
                <ImgWrapper>
                    <Img src={props.image} />
                </ImgWrapper>
                <TagWrapper>
                    <a href={props.link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>
                        <Tag>{props.tag}</Tag>
                    </a>
                </TagWrapper>
                <TitleWrapper>
                    <Title>{props.title}</Title>
                </TitleWrapper>
            </CardWrapper>
        </CardContainer>
    )
}

export default TeamMemberCard