import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StatsTable from './StatsTable'


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const StatsPanel = (props) => {
  const { classes } = props;

  const labelStyle = {fontFamily: "Alfarn", color: "#FF6363"}

  return (
    <div className={classes.root} >
      <Accordion >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" style={labelStyle}>Libido Stats</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StatsTable />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

StatsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StatsPanel);