import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    CardContent,
} from '@mui/material'
import UniqueCardRow from './UniqueCardRow'


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const Line = () => {
  const style = {
    "display": "block",
    "marginTop": "0.5em",
    "marginBottom": "0.5em",
    "marginLeft": "auto",
    "marginRight": "auto",
    "borderStyle": "inset",
    "borderWidth": "1px",
    "color": "gray",
    "background": "none ",
  }
  return(
    <hr style={style} />
  )
}

const UniquePanel = (props) => {
  const { classes } = props;

  const labelStyle = {fontFamily: "Alfarn", color: "#FF6363"}

  return (
    <div className={classes.root} >
      <Accordion >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" style={labelStyle}>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <CardContent>
            <UniqueCardRow name="Gary Ferrari" description="TV Personality & Mukbang Artisan" />
            <Line />
            <UniqueCardRow name="Gary Dipshit" description="Miserable Football Fan" />
            <Line />
            <UniqueCardRow name="Tim Creamer" description="TV Personality / Gambling Advisor" />
            <Line />
            <UniqueCardRow name="John W. Macy" description="Birthday Clown" />
            <Line />
            <UniqueCardRow name="Slave" description="Leather Pervert" />
            <Line />
            <UniqueCardRow name="Randy Jonas" description="Cowboy / Boy Band Singer" />
            <Line />
            <UniqueCardRow name="Glenn Hugs" description="Leather Daddy / Boy Band Singer" />
            <Line />
            <UniqueCardRow name="David Hobo" description="Construction Worker / Boy Band Singer" />
            <Line />
            <UniqueCardRow name="Felipe Rise" description="Native American / Boy Band Singer" />
            <Line />
            <UniqueCardRow name="Victor Willy" description="Cop / Boy Band Singer" />
        </CardContent>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

UniquePanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UniquePanel);